body[data-theme='dark'] {
  --bs-border-color: #212629;
  --bs-btn-color: #fff;
  --bs-body-color: #fff;
  --bs-body-color-rgb: 255, 255, 255;
  --bs-tooltip-color: #fff;
  --bs-body-bg: #16191b;
  --bs-secondary-bg: #1e2325;
  --bs-secondary-color: #acacac;
  --bs-tertiary-bg: #333;
  --bs-tertiary-color: #9b9b9b;
  --bs-nav-tabs-link-active-color: #fff;
  --bs-form-check-bg: #343a40;
  --bs-pagination-disabled-color: #6c757d;
}

body[data-theme='dark'] .tooltip-inner {
  color: #fff !important;
}

body[data-theme='dark'] ::placeholder {
  color: #6c757d !important;
}

body[data-theme='dark'] .bg-light {
  background-color: #16191b !important;
}

body[data-theme='dark'] .text-light {
  color: #fff !important;
}

body[data-theme='dark'] .btn-light {
  --bs-btn-color: #fff;
  --bs-btn-bg: #333;
  --bs-btn-border-color: #212629;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1e2325;
  --bs-btn-hover-border-color: #212629;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #000;
  --bs-btn-active-border-color: #212629;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f0f0f0;
  --bs-btn-disabled-bg: #16191b;
  --bs-btn-disabled-border-color: #212629;
}

body[data-theme='dark'] .form-check-input {
  background-color: #333 !important;
}

body[data-theme='dark'] .css-13cymwt-control {
  background-color: unset;
}

body[data-theme='dark'] .css-t3ipsp-control {
  background-color: unset;
}

body[data-theme='dark'] .css-1mndiq5-menu-list {
  background-color: black;
  color: #333;
}

body[data-theme='dark'] .nav-tabs {
  --bs-nav-tabs-link-active-color: #fff;
}

/* 
==================== Pivot Table ====================
*/

body[data-theme='dark'] .pvtAxisContainer {
  color: var(--bs-body-color);
  background-color: var(--bs-secondary-bg);
  border-color: var(--bs-border-color);
}

body[data-theme='dark'] .pvtVals {
  color: var(--bs-body-color);
  background-color: var(--bs-bg);
  border-color: var(--bs-border-color);
}

body[data-theme='dark'] .pvtDropdownCurrent {
  color: var(--bs-body-color);
  background-color: var(--bs-secondary-bg);
  border-color: var(--bs-border-color);
}

body[data-theme='dark'] .pvtDropdownValue {
  color: var(--bs-body-color);
  background-color: var(--bs-secondary-bg);
  border-color: var(--bs-border-color);
}

body[data-theme='dark'] .pvtAttr {
  color: var(--bs-body-color) !important;
  background-color: var(--bs-body-bg) !important;
  border-color: var(--bs-border-color) !important;
}

body[data-theme='dark'] .pvtFilterBox {
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  border-color: var(--bs-border-color);
}

body[data-theme='dark'] .pvtCheckContainer p.selected {
  color: var(--bs-body-color) !important;
  background-color: var(--bs-body-bg) !important;
  background: var(--bs-body-bg) !important;
  border-color: var(--bs-border-color) !important;
}

body[data-theme='dark'] .pvtCheckContainer {
  color: var(--bs-body-color) !important;
  background-color: var(--bs-body-bg) !important;
  background: var(--bs-body-bg) !important;
  border-color: var(--bs-border-color) !important;
}

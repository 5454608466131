.constrained {
  stroke: red;
}

.node-marker {
  cursor: grab;
}

.cs-point-label {
  font-size: 0.8em;
  font-weight: bold;
  color: black;
  background-color: transparent;
  border: 0;
  width: auto !important;
  white-space: nowrap;
}

.unconstrained {
  stroke: #4277d8;
}

.constraint {
  animation: blinker 1s linear infinite;
  opacity: 0.5;
}

.monospace {
  font-family: monospace;
}

.glowy {
  /* filter: blur(5px); */
  /* filter: drop-shadow(0 0 2px currentColor); */
  /* animation: glowy 1s alternate infinite; */
  /* animation was making the page laggy when scrolling, probably because there are so many elements to animate? Could combine similar edge infos as one big polyline */
  filter: blur(1px);
}

.operation-map-marker > div {
  aspect-ratio: 1;
  background-color: white;
  border-width: 3px;
  border-style: dashed;
  border-radius: 50%;
  opacity: 0.85;
}

.square-map-marker {
  aspect-ratio: 1;
  background-color: white;
  border: 2px solid black;
  opacity: 0.85;
}

.square-map-marker.verified {
  border: 2px solid #2d8d00;
}

.square-map-marker.unverified {
  border: 2px solid #790800;
}

@keyframes glowy {
  from {
    filter: blur(2px);
  }
  to {
    filter: blur(3px);
  }
}

@keyframes blinker {
  50% {
    opacity: 0.5;
  }
}

.isSelected {
  animation: blinker 3s infinite alternate;
}

/* this is important because when using canvas it's blocking click events through this pane  */
.leaflet-selectedPath-pane {
  pointer-events: none;
}

#dark-mode {
  pointer-events: none;
  /* this z-index is higher than everything except modals.  Going higher than modals causes the modal background to be opaque */
  z-index: 1000;
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: brightness(0.6) invert(1) contrast(3) hue-rotate(180deg)
    saturate(0.7);
}
